.custom-modal{
    max-width: 90% !important;
}

.spinner{
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%; 
}

/* .dropdown-toggle {
    width: 100px;
}

.dropdown-menu{
    min-width:unset !important;
}


.dropdown-item {
    width: 100px !important;
} */